.cookie-bar-consent {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: Arial, Helvetica, sans-serif;
  }

  content {
    min-height: 100vh;
    height: 100%;
    background-color: #eb3f43;
  }

  .cookies-bar {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FBCECE;
    color: #000;
    padding: 25px 0 27px;
    line-height: 20px;
    z-index: 100;
    transition: bottom 1s ease-in-out;

    max-height: 80vh;
    overflow: auto;

    .container {
      max-width: 1260px;
      margin: 0 auto;
      padding: 0 20px;
      position: relative;
    }

    p {
      font-size: 16px;

      a {
        color: #eb3f43;
      }
    }

    &--intro {
      margin-bottom: 20px;
      padding-right: 50px;
    }

    &--categories {
      display: flex;
      justify-content: space-between;
    }

    &--col {
      width: calc(25% - 20px);
    }

    &--label {
      margin-bottom: .5em;
    }

    &--info {

    }

    &--btn {
      display: inline-block;
      border: none;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      outline: 0;
      border-radius: 0;
      transition: .2s background-color,.2s color,.2s border-color;
      background-color: #eb3f43;
      color: #fff;
      padding: 0.75rem 1.5rem;
      min-width: 160px;
      margin-bottom: 20px;

      &:hover {
        background-color: #c91519;
      }

      &-close {
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        display: block;
        vertical-align: middle;
        margin-left: 10px;
        right: 2rem;
        top: 0px;
        transition: all ease-in-out 0.3s;
        padding: 0;
        background: none;
        border: none;
        svg {
          width: 24px;
          height: 24px;
          color: #000;
        }
      }
    }

    &--checkbox {
      width: 100%;
      height: 10px;
      position: relative;
      margin: 10px 0 -6px;
      clear: both;
      cursor: pointer;
      display: inline-block;

      input {
        display: none;
      }

      .cookies-bar--dot {
        height: 9px;
        display: block;
        width: 9px;
        left: 0px;
        padding: 0px;
        background: #000;
        transition: all ease-in-out 0.25s;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 4px);
      }

      p {
        display: inline-block;
        position: absolute;
        left: 34px;
        top: -4px;
      }

      .cookies-bar--option-checked {
        display: none;
        animation: fadeOutOption .2s ease-in;
      }

      .cookies-bar--option-default {
        animation: fadeInOption .2s ease-in;
      }

      input:checked ~ .cookies-bar--line {
        background: #eb3f43;
      }

      input:checked ~ .cookies-bar--dot {
        left: 21px;
        background: #eb3f43;
      }

      input:checked ~ p {
        font-family: "Ogilvy Serif";
        font-style: italic;
        left: 34px;
      }

      input:checked ~ .cookies-bar--option-checked {
        display: block;

        animation: fadeInOption .2s ease-in;
      }

      input:checked ~ .cookies-bar--option-default {
        display: none;

        animation: fadeOutOption .2s ease-in;
      }

      .cookies-bar--line {
        background: #000;
        height: 1px;
        content: "";
        left: 0px;
        position: absolute;
        width: 30px;
        top: 49%;
        transition: all ease-in-out 0.5s;
      }
    }

    @media screen and (max-width: 800px) {
      padding: 50px 0;
      &--categories {

        flex-wrap: wrap;
        justify-content: flex-start;
        // flex-direction: column;
      }

      &--intro {
        padding-right: 0;
      }

      &--col {
        // width: 100%;
        min-width: 70px;
        margin-right: 10px;
        width: auto;
        margin-bottom: 30px;

        &:last-of-type {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          margin-right: 0;
          width: 100%;
        }
      }

      .cookies-bar--btn-close {
        top: -30px;
      }
    }
  }

  .cookies-bar-open {
    display: block;
  }

  .page--cookies {
    padding: 100px 0;
    .container {
      max-width: 1280px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  .cookies {
    &--categories {
      display: flex;
      justify-content: space-between;
    }

    &--col {
      width: calc( 25% - 30px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &-label {
        margin-bottom: 20px;
      }

      .cookies-bar--checkbox {
        margin-top: auto;
      }

      &-more-info {
        margin-top: auto;
        position: relative;
        bottom: -8px;
        a {
          color: #eb3f43;
        }
      }

      &-text {
        margin-bottom: 20px;
      }
    }

    &--label {
      margin-bottom: 20px;
      display: block;
      text-align: center;
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 20px;
      padding-bottom: 10px;

      position: relative;

      &:after {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #000;
        bottom: 0;
        left: 0;
        content: '';
      }
    }

    @media screen and (max-width: 1040px) {
      &--categories {
        flex-wrap: wrap;
      }
      &--col {
        width: calc( 30% - 20px);
        margin-bottom: 40px;

        &-label {
          margin-bottom: 10px;
        }

        &-text {
          margin-bottom: 10px;
        }

        &:first-of-type {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 768px) {
      &--col {
        width: 100%;
      }
    }
  }

  @keyframes fadeOutOption {
    from {
      opacity: 1;
      display: block;
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      display: none;
    }
  }

  @keyframes fadeInOption {
    from {
      opacity: 0;
      display: none;
    }

    60% {
      opacity: 0;
    }

    100% {
      opacity: 1;
      display: block;
    }
  }
}