@import "normalize.css";

@import "settings.scss";
@import "typo.scss";

@import "components/header.scss";
@import "components/footer.scss";
@import "components/grid-container.scss";
@import "components/contact.scss";
@import "components/loading.scss";
@import "components/loader.scss";
@import "components/paragraphs.scss";
@import "components/category-menu.scss";
@import "components/news.scss";
@import "components/page-basic.scss";
@import "components/page-campaign.scss";
@import "components/page-news.scss";
@import "components/page-studies.scss";
@import "components/page-tender.scss";
@import "components/wysiwyg.scss";
@import "components/cookie-consent.scss";
@import "components/homepage.scss";
@import "components/performance.scss";