.contacts {
  margin-top: 40px;
  .contact {
    &-type {
        padding-bottom: 5px;
        border-bottom: 1px solid $color-black
    }

    &-box {
        margin-bottom: 50px;
    }
  }  
}

.office {

  + .office {
    border-top: 1px solid $color-black;
    margin-top: 50px;
    padding-top: 50px;
  }
      
  &-map {
    border-bottom: none !important; 
        
    img {
      max-width: 100%;
    }
  }

  &-row {
    + .office-row {
      margin-top: 60px;
    }
  }
}

@media screen and (min-width: $screen-tab) {
  .contacts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
    padding-bottom: 60px;  

    .contact {
      &-type {
        padding-bottom: 20px;
      }

      &-box {
        margin-bottom: 0;
      }
    }
  }
  
  .office {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}