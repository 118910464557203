.campaign {
  &-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-black;
    margin-bottom: 20px;

    .btn, h1 {
      margin: 0;
      font-size: 20px;
      line-height: 1.3em;
      color: $color-black
    }

    .btn {
      text-decoration: none;
      padding-right: 30px;
      transition: color .2s ease-in-out;

      &:hover {
        color: $color-red;
      }

      &:before {
        content: "←";
        margin-right: 12px;
      }
    }

    h1 {
      font-weight: 400;
    }
  }

  &-content {
    & > img {
      margin: 20px auto;
    }
  }

  &-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 20px auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-more {
    margin-bottom: 40px;
  
    &-list {
      border-top: 1px solid $color-black;
      display: grid;
      position: relative;
      grid-template-columns: 1fr;
      gap: 20px;
      padding: 40px 0 0;
      list-style-type: none;
    }

    h3 {
      font-weight: 400;
      font-size: 24px;
    }

    p,h2 {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-expertise {
    &--label {
      color: $color-red;
      font-family: $font-serif;
      letter-spacing: .8px;
      font-style: italic;
      padding-bottom: 20px;

      cursor: pointer;
  
      border-bottom: 1px solid #231f20;
  
      &:before {
        content: "";
        margin-right: 12px;
        width: 18px;
        height: 18px;
        display: inline-block;
        transform: scaleX(-1);
        background-image: url(/images/layout/arrow.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &--intro {
      &.wysiwyg {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (min-width: $screen-tab) {
  .campaign {
    &-header {
      .btn, h1 {
        font-size: 22px;
      }
    }

    &-more-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (min-width: $screen-desktop) {
  .campaign {
    &-header {
      margin-bottom: 40px;
      padding-bottom: 15px;
      .btn, h1 {
        font-size: 24px;
      }
    }

    &-more {
      &-list {
        grid-template-columns: repeat(3, 1fr);
      }
      h1,p {
        font-size: 21px;
        line-height: 29px;
      }
    }
  }
}