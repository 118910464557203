header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-white;
  transition: box-shadow 0.15s ease-in-out;

  &.nav-shadow {
    box-shadow: 0 0 7px 1px rgba(0,0,0, .15);
    transition: box-shadow 0.15s ease-in-out;;
  }   

  nav {
    padding: 20px 0 15px;
    position: relative;

    display: grid;
    grid-template-columns: 1fr;

    a {
      //text-transform: uppercase;
    }

    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  }

  .nav {
    &-lang {
      display: none;
      justify-content: flex-start;
      a {
        //text-transform: uppercase;
      }
    }

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;      
      img {
        max-width: 160px;
        width: 100%;
      }
    }

    &-toggle {
      display: block;
      width: 20px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      right: 0;
      height: 35px;
      cursor: pointer;

      span {
        display: block;
        background-color: $color-black;
        width: 20px;
        height: 3px;
        position: absolute;
        transition: all .25s ease-in-out 0s;
        left: 0;

        &:nth-of-type(1) {
          top: 10px;
        }
        &:nth-of-type(2) {
          top: 19px;
        }
      }

      &.active {
        span {
          left: 0;
          top: 15px;
          &:nth-of-type(1) {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          &:nth-of-type(2) {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }

    &-main {
      display: none;
      justify-content: flex-end;
      li + li {
        margin-left: 20px;
      }
    }

    &-mob {
      display: none;
      $navGap: 87px;
      justify-content: flex-start;
      align-items: flex-start;
      position: absolute;
      flex-direction: column;
      text-align: left;

      background-color: $color-white;
      font-weight: 700;
      padding: 10px 20px;
      top: $navGap;
      // transform: translateX(-100%);
      left: -20px;
      width: 100%;
      min-height: calc( 95svh - $navGap);
      height: 100%;
      overflow: auto;
      opacity: 0;

      .nav-lang,
      .nav-main {
        display: block;
        opacity: 0;
        
        li {
          margin: 0;

          a {
            font-size: 48px;
          }
        }
      }

      &.active {
        display: flex;
        animation: fadeIn .5s cubic-bezier(.03,.06,.1,1.04) forwards;

        .nav-lang,
        .nav-main {
          animation: fadeIn .3s ease-out forwards;
          animation-delay: .4s;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// @keyframes slideIn {
//   0% {
//     transform: translateX(-100%);
//     opacity: 0;
//   }

//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }

@media screen and (min-width: $screen-desktop) {
  header {
    nav {
      grid-template-columns: 1fr 160px 1fr;
      padding: 15px 0;
      min-height: 100px;
      box-sizing: border-box;
    }
    .nav {
      &-lang, 
      &-main {
        display: flex;
      }

      &-logo { 
        img {
          max-width: 160px;
        }
      }
  
      &-toggle {
        display: none;
      }

      &-mob {
        &.active {
          display: none;
        }
      }

    }
  }
}

body:not(.menu_transparent) header {
  

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 20px;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    background: 
    linear-gradient(
      to bottom,
      hsl(165.09, 100%, 100%) 0%,
      hsla(165.09, 100%, 100%, 0.968) 6.2%,
      hsla(165.09, 100%, 100%, 0.918) 11.9%,
      hsla(165.09, 100%, 100%, 0.854) 17.4%,
      hsla(165.09, 100%, 100%, 0.777) 22.7%,
      hsla(165.09, 100%, 100%, 0.692) 27.9%,
      hsla(165.09, 100%, 100%, 0.6) 33.2%,
      hsla(165.09, 100%, 100%, 0.506) 38.6%,
      hsla(165.09, 100%, 100%, 0.412) 44.2%,
      hsla(165.09, 100%, 100%, 0.32) 50.3%,
      hsla(165.09, 100%, 100%, 0.235) 56.7%,
      hsla(165.09, 100%, 100%, 0.158) 63.8%,
      hsla(165.09, 100%, 100%, 0.093) 71.6%,
      hsla(165.09, 100%, 100%, 0.044) 80.1%,
      hsla(165.09, 100%, 100%, 0.011) 89.5%,
      hsla(165.09, 100%, 100%, 0) 100%
    );
  }
}

body.menu_transparent header {
  background-color: transparent;
}