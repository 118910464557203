@font-face {
  font-family: OgilvySans;
  src: url(fonts/OgilvySans-Light.woff);
  font-weight: 300;
}

@font-face {
  font-family: OgilvySans;
  src: url(fonts/OgilvySans-Regular.woff);
  font-weight: 500;
}

@font-face {
  font-family: OgilvySans;
  src: url(fonts/OgilvySans-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: OgilvySerif;
  src: url(fonts/OgilvySerif-Light.woff);
  font-weight: 300;
}

@font-face {
  font-family: OgilvySerif;
  src: url(fonts/OgilvySerif-Regular.woff);
  font-weight: 500;
}

@font-face {
  font-family: OgilvySerif;
  src: url(fonts/OgilvySerif-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-style: italic;
  font-family: OgilvySerif;
  src: url(fonts/OgilvySerif-RegularItalic.woff);
  font-weight: 400;
}

$font-main: 'OgilvySans', Arial;
$font-serif: 'OgilvySerif', Arial;

* {
  font-family: $font-main;
}

.h2 {
  font-size: 20px;
  line-height: 1.4em;
  font-weight: 400;

  @media screen and (min-width: $screen-tab) {
    font-size: 22px;
    line-height: 1.364em;
  }

  @media screen and (min-width: $screen-desktop) {
    font-size: 24px;
    line-height: 1.455em;
  }  
}

.h3 {
  font-size: 20px;
  line-height: 1.4em;
  font-weight: 400;

  @media screen and (min-width: $screen-tab) {
    font-size: 22px;
    line-height: 1.364em;
  }

  @media screen and (min-width: $screen-desktop) {
    font-size: 24px;
    line-height: 1.455em;
  }  
}

p {
  color: $color-black;
  line-height: 1.5em;

  a {
    color: inherit;
    text-decoration: underline;
  }
}