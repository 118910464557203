.grid-container {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.campaign-item {
  position: relative;
  min-height: 240px;
  
  img,
  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
  
  &-inner {
    background-color: $color-red;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    cursor: pointer;
    h2,p {
      font-size: 21px;
      line-height: 29px;
      margin: 0;
      color: $color-white;
      font-weight: 400;
      text-align: center;
    }  
    h2 {
      &:before {
        display: block;
        content: '';
        width: 16px;
        height: 2px;
        background: $color-white;
        margin: 8px auto;
      }
    }
  }

  &:hover {
    .campaign-item-inner {
      opacity: 1;
    }
  }
}


@media screen and (min-width: $screen-tab) {
  .grid-container {
    grid-template-columns: repeat(6, 1fr);
  }

  // row 1
  .grid-item-1  { grid-area: 1 / 1 / 1 / 3; }
  .grid-item-2  { grid-area: 1 / 3 / 1 / 5; }
  .grid-item-3  { grid-area: 1 / 5 / 1 / 7; }
  
  // row 2
  .grid-item-4  { grid-area: 2 / 1 / 2 / 4; }
  .grid-item-5  { grid-area: 2 / 4 / 2 / 5; }
  .grid-item-6  { grid-area: 2 / 5 / 2 / 7; }

  // row 3
  .grid-item-7  { grid-area: 3 / 1 / 3 / 4; }
  .grid-item-8  { grid-area: 3 / 4 / 3 / 7; }

  // row 4
  .grid-item-9  { grid-area: 4 / 1 / 4 / 3; }
  .grid-item-10 { grid-area: 4 / 3 / 4 / 5; }
  .grid-item-11 { grid-area: 4 / 5 / 4 / 7; }

  // row 5
  .grid-item-12 { grid-area: 5 / 1 / 5 / 2; }
  .grid-item-13 { grid-area: 5 / 2 / 5 / 4; }
  .grid-item-14 { grid-area: 5 / 4 / 5 / 7; }

  // row 6
  .grid-item-15 { grid-area: 6 / 1 / 6 / 3; }
  .grid-item-16 { grid-area: 6 / 3 / 6 / 5; }
  .grid-item-17 { grid-area: 6 / 5 / 6 / 7; }

  // row 7
  .grid-item-18 { grid-area: 7 / 1 / 7 / 4; }
  .grid-item-19 { grid-area: 7 / 4 / 7 / 7; }
}

@media screen and (min-width: $screen-desktop) {
  .campaign-item {
    min-height: 340px;
  }
}