// colors

$color-pink: #f58e8f;
$color-red: #eb3f43;

$color-white: #ffffff;
$color-lightgray: #a6a6a8;
$color-gray: #3e4044;
$color-black: #231f20;

// sizes

$screen-desktop: 1260px;
$screen-tab: 768px;
$max-screen-mobile: 767px;


.container {
  max-width: calc( $screen-desktop );
  margin: 0 auto;
  padding: 0 20px;
}

img {
  display: block;
  width: 100%;
}

main {
  margin-top: 110px;
}

@media screen and (min-width: $screen-desktop) {
  main {
    margin-top: 160px;
  }
}