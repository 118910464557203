.news-list {
  list-style-type: none;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  padding: 0;
}

.news-item {
  margin-bottom: 40px;

  animation: fadeIn .7s ease-in-out forwards;

  &.news-item--pink {
    .news-item-box {
      background-color: $color-pink;
    }
  }

  &.news-item--gray {
    .news-item-box {
      background-color: $color-lightgray;
    }
  }

  &.news-item--black {
    .news-item-box {
      background-color: $color-black;
    }
  }

  &.news-item--red {
    .news-item-box {
      background-color: $color-red;
    }
  }

  &.news-item--light {
    .news-item-box {
      background-color: $color-lightgray;
    }
  }

  &-inner {
    text-decoration: none;
    color: $color-white;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-box {
    box-sizing: border-box;
    width: 100%;
    min-height: 240px;
    height: 100%;
    background-color: $color-pink;
    padding: 20px;

    transition: background-color .25s ease-in-out;

    display: flex;
    flex-direction: column;
  }

  &-category {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &-label {
    margin-top: auto;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 29px;
    text-decoration: none;
    font-family: $font-serif;
    font-weight: 400;
  }

  &-date {
    display: block;
    margin: 5px 0 10px;
    color: $color-black;
    text-align: right;
  }

  &-info {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-text {
    color: $color-black;
    margin: 0 0 15px;
  }

  &-btn {
    color: $color-red;
    display: block;
    text-align: right;
    margin-top: auto;

    &:after {
      content: '';
      margin-left: 4px;
      width: 12px;
      height: 12px;
      display: inline-block;
      background-image: url(/images/layout/arrow.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &:hover {
    .news-item {
      &-box {
        background-color: $color-red;
      }
      &-btn {
        text-decoration: underline;
      }
    }
  }
}

.news-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: flex;
    cursor: pointer;
    color: #eb3f43;
    &:after {
      content: "";
      margin-left: 4px;
      width: 12px;
      height: 12px;
      display: inline-block;
      background-image: url(/images/layout/arrow.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(90deg);
    }
  }

}

@media screen and (min-width: $screen-tab) {
  .news-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1080px) {
  .news-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: $screen-desktop) {
  .news-list {
    grid-template-columns: repeat(5, 1fr);
  }
}
