#loading {
  
  width: 100%;
  height: 100svh;

  // background-color: $color-red;

//   display: flex;
//   justify-content: center;
//   align-items: center;

//   svg {
//     max-width: 280px;
//     margin: 0 auto;  
//   }

//   // opacity: 0;

//   fill: url(#gr-simple);
// }

// #loading.loading {
//   &-start {
//     // opacity: 0;
//     // animation: fadeIn 500ms ease-in alternate;
    
//   }

//   &-progress {
//     // opacity: 1;
//   }

//   &-end {
//     // opacity: 1;
//     // animation: fadeOut 500ms ease-in forwards;
//   }
}


// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//   }

//   100% {
//     opacity: 0;
//   }
// }

:root {
  --scrollDistance: 0;
}

.nav-main,
.nav-lang {
  transition: opacity .3s ease-in;
}

body.loading-start {
  header {
    .nav-logo img {
      position: absolute;
      top: 0;
      left: 50%;
      // transform: translate(-50%, -50%) scale(3);

      transform: translateX(-50%) translateY( max(30px, calc(50vh - calc(50vh / 100 * var(--scrollDistance))))) scale(max(1, calc(3 - 0.03 * var(--scrollDistance) )));
      // translateY( calc(50vh - (50 / 100 * var(--scrollDistance))) )
    }
    
    .nav-main,
    .nav-lang {
      opacity: 0;
    }
  }

  .nav-shadow {
    box-shadow: none;
  }
}