.ogperfo {
    &--header {
        nav {
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (min-width: $screen-tab) {
                padding-left: 80px;
                padding-right: 80px;
            }

            @media screen and (max-width: $max-screen-mobile) {
                padding-left: 40px;
            }

            .nav-toggle {
                @media screen and (min-width: $screen-tab) {
                    display: none;
                }

                @media screen and (max-width: $max-screen-mobile) {
                    right: auto;
                    left: 0;
                    transform: none;
                    top: auto;
                }
            }

            .header-logo {
                font-family: OgilvySerif;
                line-height: 1.2;
                color: $color-red;

                @media screen and (min-width: $screen-tab) {
                    font-size: 50px;
                }
                @media screen and (max-width: $max-screen-mobile) {
                    font-size: calc(22 * 100vw / 375);
                }
            }

            .link {
                @media screen and (min-width: $screen-tab) {
                    position: absolute;
                    right: 0;
                }
                @media screen and (max-width: $max-screen-mobile) {
                    display: none;
                }
            }
        }
        .category-menu {
            margin-bottom: 20px;

            @media screen and (max-width: $max-screen-mobile) {
                padding: 30px 0;
                margin: 0 auto !important;
                display: none;

                li a {
                    font-size: 30px;
                }
            }
        }
        &.mob-menu-visible .category-menu {
            display: flex;
        }
        .category-menu--wrapper {
            transition: height 0.3s ease-in-out;

            @media screen and (min-width: $screen-tab) {
                height: auto !important;
            }
        }
    }

    &--main {
        padding-bottom: 20px;

        @media screen and (min-width: $screen-desktop) {
            margin-top: 205px;
        }

        .fake-heading {
            display: block;
            width: 1px;
            height: 1px;
            position: absolute;
            left: -9999px;
            color: transparent;
            background-color: transparent;
        }

        .divider {
            display: block;
            height: 1px;
            width: 100%;
            background-color: #231f20;

            @media screen and (min-width: $screen-tab) {
                margin-bottom: 40px;
            }
            @media screen and (max-width: $max-screen-mobile) {
                margin-bottom: 30px;
            }
        }

        .news {
            &-list {
                margin-top: 0;
            }
            &-box {
                display: flex;
                flex-direction: column;
                width: 100%;
                color: #eb3f43;
                text-decoration: none;

                &:hover {
                    color: #fff;
                }
            }
            &-item {
                margin-bottom: 0;
                display: flex;
                &-box {
                    background-color: #24298f;

                }
                &-date {
                    text-align: left;
                    color: inherit;
                    font-size: 16px;
                    line-height: 20px;
                    margin-top: 0;
                }
                &-label {
                    color: inherit;
                    font-size: 24px;
                    line-height: 29px;
                }
                &-info {
                    @media screen and (min-width: $screen-tab) {
                        padding-top: 15px;
                    }
                    @media screen and (max-width: $max-screen-mobile) {
                        padding-top: 10px;
                    }
                }

                @media screen and (max-width: $max-screen-mobile) {
                    &-box {
                        min-height: 160px;;
                    }
                    &-text {
                        margin: 0 0 5px;
                    }
                }

                &-btn {
                    margin-top: auto;
                    text-align: left;
                }
            }
        }

        .bp_par--image {
            position: relative;
            .img-caption {
                display: block;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                padding: 20px;
                opacity: 0;
                background-color: rgba(0,0,0,.7);
                transition: opacity .2s ease-in-out 0s;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    color: #fff;
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            &:hover {
                .img-caption {
                    opacity: 1;
                }
            }
        }
    }

    &--prefooter {
        margin-bottom: 20px;

        .divider {
            display: block;
            height: 1px;
            width: 100%;
            background-color: #231f20;

            @media screen and (min-width: $screen-tab) {
                margin-bottom: 40px;
            }
            @media screen and (max-width: $max-screen-mobile) {
                margin-bottom: 30px;
            }
        }

        .wysiwyg {
            margin-bottom: 0;
        }

        .push {
            &es {
                display: grid;
                gap: 20px;

                @media screen and (min-width: $screen-tab) {
                    grid-template-columns: repeat(5, 1fr);
                }
                @media screen and (max-width: $max-screen-mobile) {
                    grid-template-columns: 1fr;
                }
            }

            @media screen and (min-width: $screen-tab) {
                &-col-2 {
                    grid-column: span 2;
                }
            }

            &--item {
                width: 100%;
                text-decoration: none;
                background-color: #a6a6a8;
                color: #fff;
                position: relative;
                overflow: hidden;
                box-sizing: border-box;
                padding: 16px;
                transition: all .2s ease-in-out 0s;


                @media screen and (min-width: $screen-tab) {
                    height: 340px;
                }
                @media screen and (max-width: $max-screen-mobile) {
                    min-height: 246px;
                }

                &:hover {
                    background-color: #eb3f43;
                }
            }

            &--link {
                &_wrapper {
                    display: contents;
                }

                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-bottom: none;

                span {
                    display: none;
                }
            }

            &--title {
                margin-bottom: 15px;
                span {
                    font-family: OgilvySerif;
                    font-size: 24px;
                    line-height: 31px;
                }
            }

            &--david_ogilvy {
                @media screen and (min-width: $screen-tab) {
                    padding: 25px 240px 35px 30px;
                }
                @media screen and (max-width: $max-screen-mobile) {
                    padding: 30px;
                }

                &:hover {
                    background-color: #a6a6a8;
                }

                .push--title {
                    margin: 0 0 10px;

                    span {
                        font-size: 40px;
                        text-decoration: none;
                        line-height: 40px;
                        color: #eb3f43;
                    }
                }

                .push--text {
                    span {
                        font-family: OgilvySerif;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                img {
                    display: block;
                    width: 373px;
                    height: auto;
                    position: absolute;
                    right: -62px;
                    bottom: 0;

                    @media screen and (max-width: $max-screen-mobile) {
                        display: none;
                    }
                }

            }

            &--effies {
                @media screen and (max-width: $max-screen-mobile) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;
                    align-items: center;
                }

                .push--title {
                    margin-top: 15px;

                    span {
                        font-size: 18px;
                        line-height: 24px;
                        text-align: center;
                    }
                }
            }

        }
    }

    &--footer {
        .footer-inner {
            border-top: none;
            padding-top: 10px;
            padding-bottom: 30px;

            @media screen and (max-width: $max-screen-mobile) {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

/* custom wysiwyg classes */
.og {
    &-grid {
        display: grid;
        gap: 20px;

        @media screen and (min-width: $screen-tab) {
            &-columns {
                &--2 {
                    grid-template-columns: repeat(2, 1fr);;
                }
                &--3 {
                    grid-template-columns: repeat(3, 1fr);;
                }
                &--4 {
                    grid-template-columns: repeat(4, 1fr);;
                }
                &--5 {
                    grid-template-columns: repeat(5, 1fr);;
                }
            }

            &-col {
                &--1 {
                    grid-column: span 1;
                }
                &--2 {
                    grid-column: span 2;
                }
                &--3 {
                    grid-column: span 3;
                }
                &--4 {
                    grid-column: span 4;
                }
            }
        }

        @media screen and (max-width: $max-screen-mobile) {
            grid-template-columns: 1fr;
        }
    }
    &-reseni {
        h2 {
            margin-bottom: 1.5em;
            @media screen and (min-width: $screen-tab) {
                font-size: 80px;
            }
            @media screen and (max-width: $max-screen-mobile) {
                font-size: 40px;
            }
        }
        &-text p {
            font-size: 24px;
        }
        &-box {
            box-sizing: border-box;
            padding: 20px;
            display: flex;
            align-items: flex-end;
            
            &.og--red {
                background-color: #eb3f43;
            }
            &.og--black {
                background-color: #000000;
            }
            &.og--grey {
                background-color: #3e4044;
            }
            
            @media screen and (min-width: $screen-tab) {
                margin-bottom: 15px;
                height: 280px
            }
            @media screen and (max-width: $max-screen-mobile) {
                margin-bottom: 5px;
                font-size: 50px;
            }

            span {
                color: #fff;
                font-weight: bold;
                line-height: 1;
                
                @media screen and (min-width: $screen-tab) {
                    font-size: 70px;
                }
                @media screen and (max-width: $max-screen-mobile) {
                    font-size: 50px;
                }
            }

            &_wrapper p {
                line-height: 1.25;
                
                @media screen and (min-width: $screen-tab) {
                    font-size: 20px;
                }
                @media screen and (max-width: $max-screen-mobile) {
                    font-size: 16px;
                }
            }
        }

        &-contact_link {
            font-family: OgilvySerif;
            color: #eb3f43 !important;
            text-decoration: underline;
            font-size: 24px;

            &:hover {
                border-bottom: none;
            }
        }
    }
}
