.paragraph--paragraphs_tab {
    display: none;
    &.active {
        display: block;
    }
}

#paragraph_tabs {
    .active {
        color: $color-red;
    }
}

.paragraph {
    &--paragraphs_tab {
        display: none;
        &.active {
            display: block;
        }
    }
    &--bp_html {
        &.wysiwyg {
            color: #1b1b1b;
            line-height: 1.2;
            p {
                font-size: 23px;
                line-height: inherit;

                @media screen and (max-width: $max-screen-mobile) {
                    font-size: 16px;
                }
            }
        }
    }
    &--bp_image {
        img {
            width: auto;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
    &--bp_image_html {
        &.wysiwyg {
            line-height: 1.5;
        }
        .bp_par--container {
            display: flex;
            gap: 120px;

            @media screen and (max-width: $max-screen-mobile) {
                flex-direction: column !important;
                gap: 20px;
            }
        }
        .bp_par--image {
            flex: 1 1 50%;

            @media screen and (max-width: $max-screen-mobile) {

            }

            img {
                width: 100%;
                height: auto;
            }

            .img-caption {
                display: none;
            }
        }
        .bp_par--html {
            flex: 1 1 50%;
            color: #1b1b1b;
            p {
                font-size: 20px;
                line-height: inherit;

                @media screen and (max-width: $max-screen-mobile) {
                    font-size: 16px;
                }
            }
        }
    }
    &--paragraph_perex {
        margin-bottom: 40px !important;
        text-align: center;

        * {
            font-family: OgilvySerif, serif;

            @media screen and (min-width: $screen-tab) {
                font-size: 36px;
                line-height: 48px;
            }

            @media screen and (max-width: $max-screen-mobile) {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    &--section_title {
        h2 {
            font-weight: normal;

            @media screen and (min-width: $screen-tab) {
                font-size: 24px;
                line-height: 32px;
                margin: 15px 0;
            }
            @media screen and (max-width: $max-screen-mobile) {
                font-size: 20px;
                line-height: 28px;
                margin: 15px 0 5px;
            }
        }
    }
}

/* common Basic Page paragraps rules */
.bp_par {
    font-size: 10px;
    @media screen and (max-width: $max-screen-mobile) {
        font-size: 5px;
    }

    &.wysiwyg {
        margin-bottom: 0;

        h2 {
            font-size: 6.0em;
            @media screen and (max-width: $max-screen-mobile) {
                font-size: 5em;
            }
        }
    }
    &--wrapper {
        padding: 6em 0;
    }

    &--container {
        max-width: 1080px;
        margin: 0 auto;
        padding: 0 4em;

        & > :last-child {
            margin-bottom: 0;
        }
    }
}


/* custom classes */
.par {
    &--bg_color {
        &--red {
            background-color: #eb3d45;
        }
        &--grey {
            background-color: #e8e8e8;
        }
    }

    &--line_height {
        &--narrow {
            line-height: 1.15;
        }
    }
    &--padding_top {
        $start-value: 0;
        @for $i from 1 through 20 {
            &--#{$start-value + $i - 1}em {
                padding-top: #{$start-value + $i - 1}em;
            }
        }
    }
    &--padding_bottom {
        $start-value: 0;
        @for $i from 1 through 20 {
            &--#{$start-value + $i - 1}em {
                padding-bottom: #{$start-value + $i - 1}em;
            }
        }
    }
    &--margin_top {
        $start-value: 0;
        @for $i from 1 through 20 {
            &--#{$start-value + $i - 1}em {
                margin-top: #{$start-value + $i - 1}em;
            }
        }
    }
    &--margin_bottom {
        $start-value: 0;
        @for $i from 1 through 20 {
            &--#{$start-value + $i - 1}em {
                margin-bottom: #{$start-value + $i - 1}em;
            }
        }
    }
    &--font_size {
        $start-value: 0;
        @for $i from 1 through 60 {
            &--#{$start-value + $i - 1}px {
                font-size: #{$start-value + $i - 1}px !important;
            }
        }
    }

    @media screen and (max-width: $max-screen-mobile) {
        &--font_size_mob {
            $start-value: 0;
            @for $i from 1 through 60 {
                &--#{$start-value + $i - 1}px {
                    font-size: #{$start-value + $i - 1}px !important;
                }
            }
        }
    }

    &--flex_direction {
        &--row_reverse .bp_par--container {
            flex-direction: row-reverse;
        }
    }
    &--align_items {
        &--center .bp_par--container {
            align-items: center;
        }
    }

    &--full_width {
        .bp_par--container {
            max-width: 100%;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
