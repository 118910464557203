$grey-light: hsl(0, 0%, 50%) !default;
$grey: $color-black;
$grey-dark: hsl(0, 0%, 10%) !default;

$background: hsl(0, 0%, 95%) !default;
$border: hsl(0, 0%, 90%) !default;

$text: $grey !default;
$text-code: $color-black !default;
$text-strong: $grey-dark !default;
$text-light: $grey-light !default;
$text-headings: $grey-dark !default;
$text-blockquote: $grey-light !default;

$link: $color-black !default;
$link-hover: $color-red !default;
$link-visited: $color-black !default;

$background-code: $background !default;
$background-ins: lime !default;
$background-mark: yellow !default;

$table-background: hsl(0, 0%, 100%) !default;
$table-background-even: hsl(0, 0%, 98%) !default;
$table-background-hover: hsl(0, 0%, 96%) !default;
$table-border: $border !default;
$table-thead: $grey-dark !default;

// Spacing
$line-height: 1.6 !default;
$margin: 1.4em !default;

$weight-strong: 700 !default;
$weight-headings: 700 !default;

// Options
$closer-lists: false !default;
$custom-colors: true !default;
$custom-fonts: false !default;
$font-smoothing: false !default;
$heading-borders: false !default;
$show-underline: true !default;
$table-hover: false !default;
$table-striped: false !default;
$visited-links: false !default;

// Mixins
@mixin cell($font-size) {
  font-size: #{$font-size}em;
  padding: (($margin / 2) / $font-size);
}
@mixin heading($font-size, $with-border: false, $top-space: false) {
  font-size: #{$font-size}em;
  margin-bottom: ($margin / $font-size);
  @if $top-space {
    margin-top: ($margin * 2 / $font-size);
  }  @else {
    margin-top: ($margin / $font-size);
  }
  @if $heading-borders and $with-border {
    border-bottom: 1px solid $border;
    padding-bottom: ($margin / $font-size);
  }
}// Styles
.wysiwyg {
  line-height: $line-height;
  margin-bottom: 60px;
  @if $custom-colors {
    color: $text;
  }
  @if $custom-fonts {
    font-family: $family-wysiwyg;
  }
  @if $font-smoothing {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  };
  // Inlines;
  a {
    text-decoration: none;
    transition: color .2s ease-in;
    @if $custom-colors {
      color: $link;
      @if $visited-links {
        &:visited {
          color: $link-visited;
        }
      }
    }
    @if $show-underline {
      border-bottom: 1px solid;
    }    @else {
      &:hover {
        border-bottom: 1px solid;
      }
    }

    &:hover {
      color: $link-hover;
    }
  }
  abbr {
    border-bottom: 1px dotted;
    cursor: help;
  }
  cite {
    font-style: italic;
  }
  hr {
    background: $border;
    border: none;
    display: block;
    height: 1px;
    margin-bottom: $margin;
    margin-top: $margin;
  }
  img {
    vertical-align: text-bottom;
  }
  ins {
    background-color: $background-ins;
    text-decoration: none;
  }
  mark {
    background-color: $background-mark;
  }
  small {
    font-size: 0.8em;
  }
  strong {
    font-weight: $weight-strong;
    @if $custom-colors {
      color: $text-strong;
    }
  }
  sub,
  sup {
    font-size: 0.8em;
  }
  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }  // Blocks
  p,
  dl,
  ol,
  ul,
  blockquote,
  pre,
  table {
    margin-bottom: $margin;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    &:empty {
      display: none;
    }  // Headings
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $weight-headings;
    line-height: 1.2;
    &:first-child {
      margin-top: 0;
    }
    @if $custom-colors {
      color: $text-headings;
    }
  }
  h1 {
    @include heading(2.4, true);
    line-height: 1;
  }
  h2 {
    @include heading(1.6, true, true);
    line-height: 1.1;
  }
  h3 {
    @include heading(1.3);
  }
  h4 {
    @include heading(1.2);
  }
  h5 {
    @include heading(1.1);
  }
  h6 {
    @include heading(1);
  }  // Paragraphs
  @if $closer-lists {
    p {
      & + dl,
      & + ol,
      & + ul {
        margin-top: -$margin;
      }  // Lists
    }
  }
  dd {
    margin-left: $margin;
  }
  ol,
  ul {
    list-style-position: outside;
    padding-left: 1.6em;
  }
  ol {
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type: lower-greek;
          ol {
            list-style-type: decimal;
            ol {
              list-style-type: lower-alpha;
            }
          }
        }
      }
    }
  }
  ul {
    list-style-type: disc;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
        ul {
          list-style-type: circle;
          ul {
            list-style-type: disc;
            ul {
              list-style-type: circle;
            }  // Blockquote
          }
        }
      }
    }
  }
  blockquote {
    border-left: 4px solid $border;
    padding: 0.6em 1.2em;
    @if $custom-colors {
      color: $text-blockquote;
    }
    p {
      margin-bottom: 0;
    }  // Code
  }
  code,
  kbd,
  samp,
  pre {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    background-color: $background-code;
    color: $text;
    font-size: 0.9em;
    @if $custom-fonts {
      font-family: $family-code;
    }
  }
  code,
  kbd,
  samp {
    border-radius: 3px;
    line-height: $line-height / 0.9;
    padding: 0.1em 0.4em 0.2em;
    vertical-align: baseline;
    @if $custom-colors {
      color: $text-code;
    }
  }
  pre {
    overflow: auto;
    padding: 1em 1.2em;
    code {
      background: none;
      font-size: 1em;
      line-height: 1em;
    }  // Figure
  }
  figure {
    margin-bottom: $margin * 2;
    text-align: center;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  figcaption {
    font-size: 0.8em;
    margin-top: $margin / 1.6;
    @if $custom-colors {
      color: $text-light;
    };
  // Table;
  }
  table {
    width: 100%;
    pre {
      white-space: pre-wrap;
    }
  }
  th,
  td {
    @include cell(1);
    border: 1px solid $table-border;
    line-height: 1.4;
  }
  thead,
  tfoot {
    tr {
      @if $table-striped {
        background-color: $table-border;
      }      @else {
        background-color: $table-background-hover;
      }
    }
    th,
    td {
      @include cell(0.9);
      @if $custom-colors {
        color: $table-thead;
      }
      code {
        background-color: $table-background;
      }
    }
  }
  tbody {
    tr {
      background-color: $table-background;
      @if $table-striped {
        &:nth-child(even) {
          background-color: $table-background-even;
        }
        @if $table-hover {
          &:hover {
            background-color: $table-background-hover;
          }
        }
      }@else {
        @if $table-hover {
          &:hover {
            background-color: $table-background-even;
          }
        }
      }
    }
  }

  .text-align {
    &-right {
      text-align: right;
    }
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
    }
  }

  .font-ogilvyserif {
    font-family: $font-serif;

    > * {
      font-family: inherit;
    }
  }
}