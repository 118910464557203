.container-node--page {
    all: inherit;
    h1 {
        font-family: $font-serif;
        font-size: 50px;
        font-weight: 400;
        color: #ec3e44;
        text-align: center;
    }

    & > *:not(.bp_par) {
        max-width: calc( $screen-desktop );
        padding: 0 20px;
        margin-left: auto;
        margin-right: auto;
    }
}