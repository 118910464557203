footer, header {
  a, button {
    font-size: 16px;
    line-height: 1.375em;
    color: $color-black;
    text-decoration: none;
    transition: color .2s ease-in-out;
    cursor: pointer;

    &:hover {
      color: $color-red;
    }
    &.active {
      color: $color-red;
    }
  }
}

.footer {
  &-inner {
    padding: 40px 0 30px;
    border-top: 1px solid $color-black;
    display: block;
  }

  &-cookies-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: .5em;

    button {
      border: none;
      background-color: transparent;
      padding: 0;
    }
  }

  &-awards {
    max-width: 347px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .5em;
    img {
      width: 100%;

    }
  }

  &-global {
    display: inline-block;
    color: $color-red;
    margin-bottom: .5em;

    &:hover {
      color: $color-black;
    }
  }

  &-social {
    padding: 0;
    margin: 0;
    display: flex;
    li {
      list-style-type: none;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media screen and (min-width: $screen-desktop) {
  .footer {
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-cookies-links {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0;

      a, button {
        margin-left: .5em;
      }
    }

    &-awards {
      margin-bottom: 0;
    }

    &-global {
      margin-bottom: 0;
    }
  }
}