.studies-layout {
	margin-top: 0;
}

.page-studies {
	&.container {
		max-width: 1080px;
	}

	.wysiwyg {
		margin-bottom: 0;

		p {
			font-size: 18px;
		}
	}

	section {
		padding: 30px 0;
		border-bottom: 1px solid #a6a6a8;

		&:first-of-type {
			padding: 0;
			border: none;
		}

		&:last-of-type {
			border: none;
		}

		h2 {
			font-weight: 400;
			font-size: 28px;
			line-height: 1.2;
			margin: 0 0 30px;
			
		}
	}

	.btn-main {
		border: none;
		display: inline-block;
		color: $color-white;
		background-color: $color-red;
		padding: 15px 20px;
		font-size: 18px;
		line-height: 20px;
		text-decoration: none;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: #e9282c;
			;
		}
	}
}

.studies {
	&-header {
		&-partners {
			list-style-type: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			padding: 35px 0 0;
			margin: 0 0 30px;

			li {
				padding: 0 20px;
				margin-bottom: 20px;
				height: 44px;
			}

			img {
				display: block;
				width: auto;
				max-height: 100%;
			}
		}

		h1 {
			font-size: 32px;
			color: #292929;
			text-align: center;
			max-width: 860px;
			margin: 0 auto;
			text-transform: uppercase;
			line-height: 1.2;
			font-weight: 400;
		}
	}

	&-image-text {
		padding: 120px 0;
		border-bottom: 1px solid #a6a6a8;

		&-inner {
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	&-study-form {
		&-inner {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0px;

			form {
				input {
					display: block;
					width: 100%;
					border: none;
					border-bottom: 1px solid #a6a6a8;
					font-size: 20px;
					padding: 12px 6px;
				}
			}
		}

		&-info {
			font-size: 14px;
			margin: 40px 0;
			position: relative;

			a {
				color: $color-red;
			}

			&:hover {
				.studies-study-form-popup {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		&-popup {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: calc(100%);
			box-sizing: border-box;
			left: 0;
			z-index: 100;
			width: 100%;
			background-color: white;
			padding: 20px;
			transition: visibility 0s, opacity 0.15s ease-in;
			box-shadow: 0 0 15px 1px rgba(41, 41, 41, 0.15);

			p {
				font-size: 16px;
			}
		}
	}

	&-contact {
		.wysiwyg a {
			color: $color-red;
		}
	}

	&-footer {
    text-align: center;
    padding-bottom: 20px;
		padding-top: 30px;
		a, button,
		p {
			color: #a6a6a8;
			font-size: 16px;
			margin-bottom: 4px;
			text-decoration: none;
			line-height: 1.2;
      padding-right: 0;
			display: block;
		}
		button {
			border: none;
			background-color: transparent;
		}
	}
}

@media screen and (min-width: $screen-tab) {
	.page-studies {
		section {
			padding: 120px 0;

			h2 {
				font-size: 45px;
				line-height: 1.2;
				margin: 0 0 50px;
			}
		}

		// .btn-main {
		// 	font-size: 25px;
		// }

		.wysiwyg {	
			p {
				font-size: 20px;
			}
		}
	}

	.studies {
		&-header {
			&-partners {
				margin: 0 0 120px;
				li {
					margin-bottom: 0;
					+li {
						position: relative;
						padding-left: 19px;
						&:before {
							content: '';
							position: absolute;
							left: 0px;
							top: 0;
							height: 100%;
							width: 1px;
							background-color: $color-black;
						}
					}
				}
			}

			h1 {
				font-size: 70px;
			}
		}

		&-image-text {
			&-inner {
				grid-template-columns: repeat(2, 1fr);
				gap: 60px;
			}
		}

		&-study-form {
			&-inner {
				grid-template-columns: 2fr 3fr;
				gap: 60px;
			}
		}

		&-footer {
			text-align: right;
			padding-top: 0;
			a, button, p {
				display: inline-block;
				padding-right: 10px;
				font-size: 20px;
				text-decoration: none;
				&:after {
					position: relative;
					content: "";
					display: inline-block;
					width: 1px;
					height: 18px;
					top: 2px;
					margin-left: 10px;
					background-color: #a6a6a8;
				}
	
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
			button {
				border: none;
				background-color: transparent;
			}
		}
	}
}

@media screen and (min-width: $screen-desktop) {
		.studies {
			&-study-form {
				&-inner {
					grid-template-columns: 370px 540px;
					gap: 120px;
				}
			}
		}
}