$menuFontSize: 25px;

.category-menu {
	opacity: 0;
	animation: fadeIn .7s ease-in-out forwards;
	list-style-type: none;
	padding: 0;
	max-width: 1230px;
	margin: 15px auto 45px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	li {
		position: relative;
		cursor: pointer;

		a {
			display: inline-block;
			font-size: $menuFontSize;
			line-height: 1.2em;
			text-decoration: none;
			color: $color-black;
			padding: 0 25px;
			transition: color .25s ease-in-out;
			text-transform: uppercase;

			&:hover {
				color: $color-red;
			}

			&.active {
				color: $color-red;
			}
		}

		&.active {
			a {
				color: $color-red;
			}
		}

		&:after {
			content: "";
			position: absolute;
			width: 20px;
			background-image: url("/images/layout/horizontal-divider.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: auto 100%;
		}

		&:last-of-type:after {
			display: none;
		}

		&.no-div:after {
			display: none;
		}
	}

	&.category-menu--small {
		$menuFontSize: 24px;
		margin: 0 0 20px -10px;
		justify-content: flex-start;

		li {
			a {
				font-size: $menuFontSize;
				padding: 0 12px;
			}
			&:after {
				font-size: $menuFontSize;
			}
		}
	}
}

@media screen and (min-width: $screen-tab) {
	$menuFontSize: 37px;

	.category-menu {
		li {
			a {
				font-size: $menuFontSize;
			}

			&:after {
				top: 8px;
				bottom: 8px;
				right: -10px;
			}
		}
	}
}

@media screen and (max-width: $max-screen-mobile) {
	.category-menu {
		flex-direction: column;
		row-gap: 5px;
		margin: 30px auto;

		li {
			&:after {
				top: 5px;
				bottom: 5px;
				right: 0;
			}
		}
	}
}