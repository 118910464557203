.homepage {
    &--header {
        .nav-logo {
            @media (min-width: 1260px) {
                all: initial;
            }

            @media screen and (max-width: 767px) {
                a {
                    margin-top: initial !important;
                    transform: initial !important;

                    svg {
                        width: 160px !important;
                    }
                }
            }
        }

        @media (min-width: 768px) and (max-width: 1259px) {
            nav {
                padding: 0;
                min-height: 100px;
            }
        }

        @media screen and (max-width: 767px) {
            nav {
                min-height: 70px;
                padding: 10px 0;
                box-sizing: border-box;
            }
        }

        .nav-mob {
            padding: 30px 20px;
            top: 90px;
            min-height: calc(100svh - 90px);
            right: -20px;
            width: auto;
            box-sizing: border-box;
            z-index: 100;
        }
    }

    &--main {
        margin-top: 0 !important;
        min-height: 100vh;
    }

    &--red_grid {
        background-color: #eb3f43;
        display: block;
        left: 0;
        overflow-x: auto;
        overflow-y: hidden;
        position: fixed;
        top: 0;
        transition: background-color .9s cubic-bezier(0.165,0.84,0.44,1) 150ms;
        width: 100vw;
        
        &--inner {
            cursor: grab;
            // height: calc(100vh + 50px);
            height: 100vh; // temp
            overflow-x: auto;
            width: 100%;
            overflow-y: hidden;
            --red_grid-padding_top: 120px;
            --red_grid-padding_bottom: 40px;
            --red_grid-padding_sum: calc(var(--red_grid-padding_top) + var(--red_grid-padding_bottom));
            
            padding: var(--red_grid-padding_top) 0 var(--red_grid-padding_bottom);
            box-sizing: border-box;

            // temp
            &--test_box {
                width: 100px;
                height: 100px;
                margin: 25px;
                background: rgb(223, 188, 188);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                color: #fff;
            }
        }
        &--slider {
            display: grid !important;
            grid-template-columns: repeat(6, 1fr);
            --red_grid-row_gap: 5vh;
            grid-template-rows: repeat(4, calc( (100vh - var(--red_grid-padding_sum) - 3 * var(--red_grid-row_gap)) / 4) );
            column-gap: 40px;
            row-gap: var(--red_grid-row_gap);
            padding: 0 20px;
            box-sizing: border-box;

            &--item {
                position: relative;
                padding: 0 30px;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-decoration: none;
                }

                &--bg {
                    display: block;
                    position: relative;
                    width: 100%;
                    aspect-ratio: 630 / 340;
                    overflow: hidden;
                    max-height: 100%;
                    
                    &_inner {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        transition: all 0.15s ease-in-out;
                    }

                    &:hover &_inner {
                        transform: scale(1.2);
                    }
                }

                &--text {
                    color: #fff;
                    margin-top: 0.2em;
                    font-size: 16px;
                    line-height: 1.2;
                    opacity: 0;
                    transition: all 0.5s ease-in-out;
                    > * {
                        font-family: $font-serif;
                        font-style: italic;
                    }
                }
                
                &:hover &--text {
                    opacity: 1;
                }
            }
        }
    }

    &--red_claim {
        margin-top: 200vh;
        height: 100vh;
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            bottom: 100%;
            height: 100vh;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            background-image: linear-gradient(to top,rgb(235,63,67),rgba(235,63,67,0.945) 7.9%,rgba(235,63,67,0.883) 15.3%,rgba(235,63,67,0.815) 22.2%,rgba(235,63,67,0.743) 28.7%,rgba(235,63,67,0.667) 34.8%,rgba(235,63,67,0.589) 40.6%,rgba(235,63,67,0.509) 46.2%,rgba(235,63,67,0.429) 51.7%,rgba(235,63,67,0.348) 57.2%,rgba(235,63,67,0.269) 62.8%,rgba(235,63,67,0.193) 68.7%,rgba(235,63,67,0.121) 75.2%,rgba(235,63,67,0.059) 82.6%,rgba(235,63,67,0.015) 91.2%,rgba(235,63,67,0));
        }

        &.branding-sticky  &--inner {
            bottom: 0;
            left: 0;
            position: fixed;
            width: 100%;
        }
        &--inner {
            background-color: #eb3f43;
            min-height: 100vh;
            color: #000;
            font-family: $font-serif;
            display: flex;
            align-items: center;
            padding: calc(120px + 4.5vw) 0 60px; 
            box-sizing: border-box;
        }
        &--text {
            max-width: 1440px;
            padding: 0 160px;
            margin: 0 auto;
            box-sizing: border-box;
            p {
                font-family: $font-serif;
                font-size: calc(60 * 100vw / 1920);
                line-height: 1.2;
            }
        }
    }

    &--container_wrapper {
        position: relative;
        z-index: 1;
        background-color: #fff;
        margin-top: calc(100vh - 120px);
        padding-bottom: 20px;

        &:before {
            content: '';
            bottom: 100%;
            height: calc(100vh - 120px);
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            background-image: linear-gradient(to top,hsl(0,0%,100%),hsla(0,0%,100%,0.94505) 7.9%,hsla(0,0%,100%,0.88294) 15.3%,hsla(0,0%,100%,0.81522) 22.2%,hsla(0,0%,100%,0.7426) 28.7%,hsla(0,0%,100%,0.66692) 34.8%,hsla(0,0%,100%,0.58891) 40.6%,hsla(0,0%,100%,0.50925) 46.2%,hsla(0,0%,100%,0.42866) 51.7%,hsla(0,0%,100%,0.34817) 57.2%,hsla(0,0%,100%,0.2693) 62.8%,hsla(0,0%,100%,0.19309) 68.7%,hsla(0,0%,100%,0.12126) 75.2%,hsla(0,0%,100%,0.05882) 82.6%,hsla(0,0%,100%,0.01457) 91.2%,hsla(0,0%,100%,0));
        }

        #campaigns {
            @media screen and (min-width: 641px) {
                padding-top: 120px;
            }
            @media screen and (max-width: 640px) {
                padding-top: 80px;
            }
        }

        @media (min-width: 768px) {
            #campaigns {
                min-height: 80vh;
            }
        }
    }

    &--footer {
        position: relative;
        z-index: 1;
        background-color: #fff;
    }
}


.branding {    
    color: #eb3f43;

    &_slider {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(4, 20vh);
        column-gap: 20px;
        row-gap: 5vh;

        &--item {
            overflow: hidden;
            position: relative;

            a {
                display: block;
            }
        }
    }

    &__logo {
        fill: currentColor;
        height: auto;
    }
}

body {
    --menu-transition: 0;
}

body.menu_transparent {
    .branding {
        color: rgb( 
            calc(255 - (255 - 235) * (var(--menu-transition) / 100)), 
            calc(255 - (255 -  63) * (var(--menu-transition) / 100)),
            calc(255 - (255 -  67) * (var(--menu-transition) / 100)) 
        );

        &--splash {
            transition: opacity .3s ease-in-out, color 0.1s ease-in-out;
            z-index: 0;
    
            @media (min-width: 768px) {
                left: 50%;
                top: 50%;
                height: 100vh;
                position: absolute;
                pointer-events: none;
            }
        }
    
        &__logo {            
            @media (min-width: 768px) {
                min-width: var(--logo-scroll-min-width);
                margin-top: 50vh;
                max-width: 700px;
                transform: translateY(-50%);
            }
        }
    }
    @media (min-width: 1260px) {
        header {
            a:hover {
                color: #fff;
            }
            a.active {
                color: rgb( 
                    calc(255 - (255 - 235) * (var(--menu-transition) / 100)), 
                    calc(255 - (255 -  63) * (var(--menu-transition) / 100)),
                    calc(255 - (255 -  67) * (var(--menu-transition) / 100)) 
                );
            }
        }
    }
}

body:not(.menu_transparent) {
    .branding {
        &--splash {
            position: relative;
            top: 4px;
        }

        @media (min-width: 768px) and (max-width: 1259px) {
            margin-top: 0 !important;
            transform: none !important;
        }
    }
}
body.no_splash {
    .branding--splash {
        --scroll-distance: 100 !important;
        --logo-scroll-position: -50% !important;
        --logo-scroll-margin: 50px !important;
    }

    .homepage--container_wrapper {
        margin-top: 0 !important;
    }
}


.swiper-wrapper {
    transition-timing-function: linear !important;
}
